<template>
	<div>
		<div class="md:flex md:items-center md:justify-between mb-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Media
				</h2>
			</div>
		</div>

		<div class="mb-8">
			<div class="block flex border-b border-gray-200">
				<div class="flex-1">
					<nav class="-mb-px flex space-x-8" aria-label="Tabs">
						<button
							:class="[
								tab == 'images'
									? 'border-blue-500 text-blue-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
							]"
							:aria-current="tab == 'images' ? 'page' : undefined"
							@click.prevent="tab = 'images'"
						>
							Images
						</button>
						<button
							:class="[
								tab == 'videos'
									? 'border-blue-500 text-blue-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
							]"
							:aria-current="tab == 'videos' ? 'page' : undefined"
							@click.prevent="tab = 'videos'"
						>
							Videos
						</button>
						<button
							:class="[
								tab == 'documents'
									? 'border-blue-500 text-blue-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
							]"
							:aria-current="tab == 'documents' ? 'page' : undefined"
							@click.prevent="tab = 'documents'"
						>
							Documents
						</button>
					</nav>
				</div>

				<div v-if="tab == 'images'" class="mt-3 flex h-10 sm:mt-0 sm:ml-4">
					<RefreshTableButton @refreshTable="refreshImagesTable" />

					<button
						class="relative ml-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
						@click="scrollTo('image')"
					>
						<PhotographIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Add Image
					</button>
				</div>

				<div v-if="tab == 'videos'" class="mt-3 flex h-10 sm:mt-0 sm:ml-4">
					<RefreshTableButton @refreshTable="refreshVideosTable" />

					<button
						class="relative ml-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
						@click="scrollTo('video')"
					>
						<FilmIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Add Video
					</button>
				</div>

				<div v-if="tab == 'documents'" class="mt-3 flex h-10 sm:mt-0 sm:ml-4">
					<RefreshTableButton @refreshTable="refreshDocumentsTable" />

					<button
						class="relative ml-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
						@click="scrollTo('document')"
					>
						<FilmIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Add Document
					</button>
				</div>
			</div>
		</div>

		<div class="">
			<div v-if="tab == 'images'">
				<div class="flex flex-col">
					<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
							<ag-grid-vue
								style="height: 725px"
								class="ag-theme-alpine"
								:grid-options="gridOptionsImages"
								:column-defs="columnDefsImages"
								:default-col-def="defaultColDef"
								:row-data="media"
								:modules="modules"
								:pagination="true"
								:pagination-page-size="50"
								:enable-cell-text-selection="true"
								:ensure-dom-order="true"
							></ag-grid-vue>
						</div>

						<div id="image-upload-form" class="px-10 mb-4">
							<MediaImage
								:media-upload-accept="['image/png', 'image/jpeg']"
								:editing="editingImage"
								:media="edit"
								@mediaCreated="mediaCreated"
								@mediaUpdated="mediaUpdated"
								@editCancel="editReset"
							/>
						</div>
					</div>
				</div>
			</div>

			<div v-if="tab == 'videos'">
				<div class="flex flex-col">
					<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
							<ag-grid-vue
								style="height: 725px"
								class="ag-theme-alpine"
								:grid-options="gridOptionsVideos"
								:column-defs="columnDefsVideos"
								:default-col-def="defaultColDef"
								:row-data="media"
								:modules="modules"
								:pagination="true"
								:pagination-page-size="50"
								:enable-cell-text-selection="true"
								:ensure-dom-order="true"
							></ag-grid-vue>
						</div>

						<div id="video-upload-form" class="px-10 mb-4">
							<MediaVideo
								:media-upload-accept="['video/mp4']"
								:editing="editingVideo"
								:media="edit"
								@mediaCreated="mediaCreated"
								@mediaUpdated="mediaUpdated"
								@editCancel="editReset"
							/>
						</div>
					</div>
				</div>
			</div>

			<div v-if="tab == 'documents'">
				<div class="flex flex-col">
					<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
							<ag-grid-vue
								style="height: 725px"
								class="ag-theme-alpine"
								:grid-options="gridOptionsDocuments"
								:column-defs="columnDefsDocuments"
								:default-col-def="defaultColDef"
								:row-data="media"
								:modules="modules"
								:pagination="true"
								:pagination-page-size="50"
								:enable-cell-text-selection="true"
								:ensure-dom-order="true"
							></ag-grid-vue>
						</div>

						<div id="document-upload-form" class="px-10 mb-4">
							<MediaDocument
								:media-upload-accept="['application/pdf']"
								:editing="editingDocument"
								:media="edit"
								@mediaCreated="mediaCreated"
								@mediaUpdated="mediaUpdated"
								@editCancel="editReset"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject, ref, provide, computed } from 'vue'
import { useToast } from 'vue-toastification'

import { AgGridVue } from '@ag-grid-community/vue3'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import TableActionsMedia from '@/components/TableActionsMedia.vue'

import { PhotographIcon, FilmIcon } from '@heroicons/vue/solid'

export default {
	name: 'Media',
	components: {
		AgGridVue,
		PhotographIcon,
		FilmIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		const media = ref(null)

		return {
			api,
			toast,
			media,
		}
	},
	data() {
		return {
			tab: 'images',
			slideOpen: false,
			slideTitle: '',
			slideComponent: '',

			editingImage: false,
			editingVideo: false,
			editingDocument: false,
			edit: {},

			columnDefsImages: null,
			columnDefsVideos: null,
			columnDefsDocuments: null,
			defaultColDef: null,

			modules: [ClientSideRowModelModule],
		}
	},
	watch: {
		tab: function(val) {
			if (val === 'images') {
				this.fetchImages()
			} else if (val === 'videos') {
				this.fetchVideos()
			} else if (val === 'documents') {
				this.fetchDocuments()
			}
		},
	},
	beforeMount() {
		this.gridOptionsImages = {
			context: {
				componentParent: this,
			},
		}

		this.gridOptionsVideos = {
			context: {
				componentParent: this,
			},
		}

		this.gridOptionsDocuments = {
			context: {
				componentParent: this,
			},
		}

		this.columnDefsImages = [
			{ field: 'original_filename', flex: 1 },
			{ field: 'alt_text', flex: 1 },
			{ field: 'category.name' },
			{ field: 'dimensions' },
			// { field: 'url', flex: 1 },
			{ field: 'updated_at' },
			{
				headerName: 'Actions',
				width: 300,
				cellRendererFramework: TableActionsMedia,
				cellRendererParams: {
					mediaType: 'image',
					appApi: this.api,
					parent: this,
				},
			},
		]

		this.columnDefsVideos = [
			{ field: 'original_filename', flex: 1 },
			{ field: 'title', flex: 1 },
			{ field: 'category.name' },
			{ field: 'dimensions' },
			// { field: 'url', flex: 1 },
			{ field: 'updated_at' },
			{
				headerName: 'Actions',
				width: 300,
				cellRendererFramework: TableActionsMedia,
				cellRendererParams: {
					mediaType: 'video',
					appApi: this.api,
					parent: this,
				},
			},
		]

		this.columnDefsDocuments = [
			{ field: 'original_filename', flex: 1 },
			{ field: 'title', flex: 1 },
			{ field: 'category.name' },
			// { field: 'url', flex: 1 },
			{ field: 'updated_at' },
			{
				headerName: 'Actions',
				width: 300,
				cellRendererFramework: TableActionsMedia,
				cellRendererParams: {
					mediaType: 'document',
					appApi: this.api,
					parent: this,
				},
			},
		]

		this.defaultColDef = {
			resizable: true,
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
		}
	},
	mounted() {
		this.gridApiImages = this.gridOptionsImages.api
		this.gridApiVideos = this.gridOptionsVideos.api
		this.gridApiDocuments = this.gridOptionsDocuments.api
		this.fetchImages()
		// this.fetchVideos()
		// this.fetchDocuments()
	},
	methods: {
		fetchImages(reload = false) {
			this.loaderShow()

			this.media = null
			this.api.images
				.all()
				.then(data => {
					this.media = data
				})
				.catch(error => {
					this.toast.error('Error fetching images:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		fetchVideos(reload = false) {
			this.loaderShow()

			this.media = null
			this.api.videos
				.all()
				.then(data => {
					this.media = data
				})
				.catch(error => {
					this.toast.error('Error fetching videos:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		fetchDocuments(reload = false) {
			this.loaderShow()

			this.media = null
			this.api.documents
				.all()
				.then(data => {
					this.media = data
				})
				.catch(error => {
					this.toast.error('Error fetching documents:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		scrollTo(location) {
			document.getElementById(location + '-upload-form').scrollIntoView()
		},

		openSlide(component, title) {
			this.slideTitle = title
			this.slideComponent = component
			this.slideOpen = true
		},

		closeSlide() {
			this.slideOpen = false
			this.slideTitle = ''
			this.slideComponent = ''
			// this.creating = ''
		},

		mediaCreated() {
			if (this.tab === 'images') {
				this.fetchImages()
			} else if (this.tab === 'videos') {
				this.fetchVideos()
			} else if (this.tab === 'documents') {
				this.fetchDocuments()
			}
		},

		mediaUpdated() {
			if (this.tab === 'images') {
				this.editingImage = false
				this.fetchImages()
			} else if (this.tab === 'videos') {
				this.editingVideo = false
				this.fetchVideos()
			} else if (this.tab === 'documents') {
				this.editingDocument = false
				this.fetchDocuments()
			}
		},

		refreshImagesTable() {
			this.fetchImages(true)
		},

		refreshVideosTable() {
			this.fetchVideos(true)
		},

		refreshDocumentsTable() {
			this.fetchDocuments(true)
		},

		editReset() {
			this.edit = {}
			this.editingImage = false
			this.editingVideo = false
			this.editingDocument = false
		},

		editMedia(mediaType, media) {
			this.editReset()

			setTimeout(() => {
				this.edit = JSON.parse(JSON.stringify(media))

				if (mediaType === 'image') {
					this.editingImage = true
				} else if (mediaType === 'video') {
					this.editingVideo = true
				} else if (mediaType === 'document') {
					this.editingDocument = true
				}

				this.scrollTo(mediaType)

				console.log(mediaType, this.edit, this.editingImage)
			}, 50)
		},
	},
}
</script>
